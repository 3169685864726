/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";
import { MEDIA_ROOT } from "config.js";

const styles = theme => ({
  table: {
    minWidth: 650
  },
  progressBar: {
    width: "100%"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    },
    "&:last-child td, &:last-child th": {
      border: 0
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.background.paper
    }
  },
  avatarCell: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  mobileRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "5px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center"
    }
  },
  mobileChart: {
    width: "100%",
    marginTop: "5px"
  }
});

class GoalProgressLeaderboard extends Component {
  constructor(props) {
    super(props);
    const goalIdFromPath = props.match && props.match.params.goalId; // Get goalId from path if available
    this.state = {
      goalId: props.goalId || goalIdFromPath, // Use goalId from props or fallback to path
      goalDetails: null, // Goal details object
      progressDetails: [] // Array of { member__id, member__username, member__avatar, progress_value, created_at }
    };
  }

  componentDidMount() {
    const { axios } = this.props;
    const { goalId } = this.state;

    if (!goalId) {
      console.error("Goal ID is required but not found.");
      return;
    }

    axios.get(`/api/goals/${goalId}/progress`).then(response => {
      const { goal, progress } = response.data;
      this.setState({
        goalDetails: goal,
        progressDetails: progress.sort(
          (a, b) => b.progress_value - a.progress_value
        ) // Sort by progress_value in descending order
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { goalDetails, progressDetails } = this.state;

    if (!goalDetails) {
      return <div>Loading...</div>;
    }

    const percentageCompletion =
      (goalDetails.metadata && goalDetails.metadata.percentage_completion) || 0;

    const totalProgressValue = progressDetails.reduce(
      (sum, progress) => sum + progress.progress_value,
      0
    );

    return (
      <div>
        <h2>{goalDetails.name} - Progress Leaderboard</h2>
        <p>
          <strong>Target:</strong> {goalDetails.target_value}{" "}
          {goalDetails.target_metric} <br />
          <strong>Total Completion:</strong> {totalProgressValue.toFixed(1)}{" "}
          {goalDetails.target_metric} -- {percentageCompletion.toFixed(1)}%
        </p>
        <LinearProgress
          variant="determinate"
          value={percentageCompletion}
          className={classes.progressBar}
        />
        <div component={Paper} style={{ overflowX: "auto" }}>
          <Table className={classes.table} aria-label="leaderboard table">
            <TableHead>
              <TableRow>
                <TableCell>Member Contribution</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {progressDetails.map((progress, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell component="th" scope="row">
                    <div className={classes.mobileRow}>
                      <div className={classes.avatarCell}>
                        {progress.member__avatar ? (
                          <Avatar
                            src={`${MEDIA_ROOT}/media/${
                              progress.member__avatar
                            }`}
                            alt={progress.member__username}
                          />
                        ) : (
                          <Avatar>
                            {progress.member__username
                              .split(" ")
                              .map(name => name[0])
                              .join("")
                              .toUpperCase()}
                          </Avatar>
                        )}
                        {progress.member__username}
                      </div>
                      <span>
                        {progress.progress_value.toFixed(1)}{" "}
                        {goalDetails.target_metric}
                      </span>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (progress.progress_value / goalDetails.target_value) *
                        100
                      }
                      className={classes.mobileChart}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GoalProgressLeaderboard);
