import { API_ROOT } from "config.js";

const GOAL_LIST_ENDPOINT = `${API_ROOT}/api/goals/`;

export async function getGoals(axios) {
  const response = await axios.get(GOAL_LIST_ENDPOINT);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
