/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress"; // Import progress bar component
import moment from "moment"; // Import moment for date calculations
import GroupIcon from "@material-ui/icons/Group"; // Import group icon

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Goal from "views/Goal/Goal.jsx"; // Updated import
import GoalProgressLeaderboard from "./GoalProgressLeaderboard"; // Import the leaderboard component

import { getGoals } from "../../services/goalsDataProvider"; // Updated import

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Goals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeGoal: props.match.params.goalName,
      goals: [],
      showLeaderboard: false, // State to toggle leaderboard view
      selectedGoalId: null // State to store the selected goal ID
    };
  }

  componentDidMount() {
    if (
      this.state.activeGoal === ":goalName" || // Updated condition
      this.state.activeGoal == null
    ) {
      getGoals(this.props.axios).then(
        (
          data // Updated function call
        ) => this.setState({ goals: data.results }) // Updated state update
      );
    }
  }

  handleLinkClick = goalName => e => {
    e.preventDefault();
    this.setState({ activeGoal: goalName }); // Updated state update
  };

  handleProgressClick = goalId => e => {
    e.preventDefault();
    this.setState({ showLeaderboard: true, selectedGoalId: goalId }); // Show leaderboard for the selected goal
  };

  renderGoals() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    const goals = this.state.goals || []; // Ensure goals is not null
    return (
      <GridContainer>
        {goals.map(goal => {
          const daysLeft = moment(goal.target_date).diff(moment(), "days"); // Calculate days left
          const localizedTargetDate = moment(goal.target_date).format("LL"); // Localized format
          const percentageCompletion =
            (goal.metadata && goal.metadata.percentage_completion) || 0;
          const canEdit =
            this.props.user != null && this.props.user.user_id === goal.admin;
          return (
            <GridItem xs={12} sm={6} md={4} key={`goal-${goal.id}`}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Icon>flag</Icon>
                  </CardIcon>
                  <div className={classes.goalNameCard}>
                    <h4 style={{ margin: 0 }}>{goal.name}</h4>
                    {goal.is_shared && (
                      <GroupIcon style={{ fontSize: "1rem" }} />
                    )}
                  </div>
                  <div className={classes.goalTimeLeft}>
                    {daysLeft > 0
                      ? `${daysLeft} days left`
                      : "Target date passed"}
                  </div>
                </CardHeader>
                <CardBody>
                  <p>{goal.description}</p>
                  <p>
                    <strong>Target Date:</strong> {localizedTargetDate}
                  </p>
                  <LinearProgress
                    variant="determinate"
                    value={percentageCompletion}
                  />
                  <p>
                    <strong>Completion:</strong>{" "}
                    {percentageCompletion
                      ? `${percentageCompletion.toFixed(1)}%`
                      : "0%"}
                  </p>
                </CardBody>
                <CardFooter stats>
                  <div className={classes.goalActionButtonsContainer}>
                    <Button
                      type="button"
                      color="info"
                      size="sm" // Set button size to small
                      onClick={this.handleProgressClick(goal.id)} // Updated to handle progress click
                    >
                      Progress
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      size="sm" // Set button size to small
                      onClick={this.handleLinkClick(goal.name)}
                      disabled={!canEdit} // Disable button if user cannot edit
                    >
                      Details
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  }

  renderGoal() {
    // Updated method name
    return (
      <Goal
        history={this.props.history}
        axios={this.props.axios}
        name={this.state.activeGoal} // Updated property
      />
    );
  }

  render() {
    if (this.state.showLeaderboard) {
      return (
        <GoalProgressLeaderboard
          axios={this.props.axios}
          goalId={this.state.selectedGoalId} // Pass the selected goal ID
        />
      );
    }

    if (
      this.state.activeGoal !== ":goalName" &&
      this.state.activeGoal != null
    ) {
      // Updated condition
      return this.renderGoal(); // Updated method call
    }
    return this.renderGoals();
  }
}

export default withStyles(styles)(Goals);
