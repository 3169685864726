import { API_ROOT } from "config.js";

const USER_FORM_LIST_ENDPOINT = `${API_ROOT}/api/quiz/userforms/`;
const QUIZ_RESULTS_ENDPOINT = `${API_ROOT}/api/quiz/results/`;
const QUIZ_LIST_ENDPOINT = `${API_ROOT}/api/quiz/`;
const ANSWER_DETAILS_ENDPOINT = `${API_ROOT}/api/quiz/answers/`;
const QUIZ_DETAILS_ENDPOINT = `${API_ROOT}/api/quiz/questions/`;

export async function getUserForms(axios) {
  const response = await axios.get(`${USER_FORM_LIST_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getQuizes(axios, quizType=null) {
  const response = await axios.get(`${QUIZ_LIST_ENDPOINT}?type=${quizType}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getQuestions(axios, quizName) {
  const response = await axios.get(`${QUIZ_DETAILS_ENDPOINT}?form=${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getData(axios, question_id) {
  const response = await axios.get(`${QUIZ_DETAILS_ENDPOINT}${question_id}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function submitAnswer(axios, answerData) {
  const response = await axios.post(`${ANSWER_DETAILS_ENDPOINT}`, answerData);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getResults(axios, quizName) {
  const response = await axios.get(`${QUIZ_RESULTS_ENDPOINT}?form=${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
